import React from 'react';
import image1 from './img/Hamburger 01.png'; // Import de chaque image individuellement
import image2 from './img/Hamburger 02.png';
import image3 from './img/Hamburger 03.png';
import image4 from './img/Hamburger 04.png';
import image5 from './img/Hamburger 05.png';
import image6 from './img/Hamburger 06.png';
import image7 from './img/Hamburger 07.png';
import image8 from './img/Hamburger 08_1.png';
import image9 from './img/Hamburger 09.png';
import image10 from './img/Hamburger 10.png';
import image0 from './img/step 00.png';
import image11 from './img/step 01.png';
import image12 from './img/step 02.png';
import image13 from './img/step 03.png';
import image14 from './img/step 04.png';
import image15 from './img/step 05.png';
import image16 from './img/step 06.png';
import image17 from './img/step 07.png';
import image18 from './img/step 08.png';
import image19 from './img/step 09.png';
import image20 from './img/step 10.png';
import image21 from './img/step 11.png';
import image22 from './img/step 12.png';
import image23 from './img/step 13.png';
import image24 from './img/step 14.png';
import image25 from './img/step 15.png';
import image26 from './img/step 16.png';
import image27 from './img/step 17.png';

import fb from './img/flatBrains.png';
import plaketXL from './img/PlaketXL LOGO FINAL LAST.png';






const images = [plaketXL,image0,image11,image12,image13,image14,image15,image16,image17,image18,image19,image20,image21,image22,image23,image25,image24,image26,image27,fb];

function PlaketXlBuildGuide() {


    return (
        <div style={styles.container}>
          {images.map((image, index) => (
            <img
              key={index}
              src={image}
              alt={`Image ${index + 1}`}
              style={styles.image}
            />
          ))}
        </div>
      );
    };
    
    const styles = {
        container: {
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center', // Centre chaque image horizontalement
          width: '80vw', // Limite la largeur du conteneur à 80% de la fenêtre
          minHeight: '100vh',
        },
        image: {
          maxWidth: '100%', // Limite chaque image à la largeur du conteneur
          height: 'auto', // Conserve la proportion des images
          marginBottom: '50px', // Espace entre chaque image
          backgroundColor: '#FBFBFB',
        },
      };

export default PlaketXlBuildGuide;


