import React from 'react';
import Spline from '@splinetool/react-spline';
import { useRef,useEffect, useState } from 'react';



 export default function Keyboard() {
  const spline = useRef();
  function onLoad(splineApp) {
    // save the app in a ref for later use
    spline.current = splineApp;
  }
  
  const SetVarSpline = (e,keyActivation)=>{
    spline.current.setVariable(e.code + keyActivation, !spline.current.getVariable(e.code + keyActivation));
  }

  useEffect(() => {
    document.body.addEventListener('keydown', e => {
      if (e.code === 'Tab') {
        e.preventDefault();
      }
      SetVarSpline(e,'Down')
    
    }
    )
      return () =>{
        document.body.addEventListener('keydown', e => {
            SetVarSpline(e,'Down')
          
          }
          )
      }
  },[]);

  useEffect(() => {

    document.body.addEventListener('keyup', e => {
      SetVarSpline(e,'Up')
    }
    )
        return () =>{
          document.body.addEventListener('keyup', e => {
            SetVarSpline(e,'Up')
            }
            )
        }
  },[]);

  return (
    <div className="App-keyboard">
        <Spline scene="https://prod.spline.design/0TDNCEbbgZHLxKo5/scene.splinecode"
        onLoad={onLoad} style={{height: "100vh",width: "100vw" }}
        />
    </div>
  );
 }
 

