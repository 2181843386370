import Keyboard from './components/keyboard';
import Header from './components/Header';
import { useRef,useEffect, useState } from 'react';
import '../src/style/App.css'
import Spline from '@splinetool/react-spline';
import AnimatedLogo from './components/AnimatedLogo';




function App() {
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const isHeightGreaterThanWidth = windowSize.height > windowSize.width;

  return (
    <div className="App">
      {isHeightGreaterThanWidth ? <AnimatedLogo /> : <Keyboard />}
    </div>
  );
}

export default App;

